<template>
  <div>
    <b-card title="Divisiones horizontales" class="text-center">
      <b-row class="mb-1">
          <b-col cols="12">
            <h5> Medidas Estructura ( {{total_width}}  X  {{total_height}} )</h5>
          </b-col>
          <table id="esquema" class="table" style="height : 0 ;">
            <tr v-if="tapeta[0]">
              <td class="tapeta" :colspan="tapetaCol + vdivisions.length -1"></td>
            </tr>   
            <tr :style="'height :'+ register_y +'px'" v-if="register">
              <td class="tapeta" v-if="tapeta[2]" rowspan="2">&nbsp;</td>
              <td :colspan="vdivisions.length" style="background-color: #BBB;"> <div class="innerDiv"></div></td>
              <td class="tapeta" v-if="tapeta[3]" rowspan="2"></td>    
            </tr>          
            <tr :style="'height :'+ table_y +'px'">
              <td class="tapeta" v-if="!register && tapeta[2]"></td>
              <td v-for="division,index in vdivisions" :key="index" 
                  :style="'width : '+ (cols[index].width/100) * table_x +'px'" 
                  :class="(colSelected == index ? 'selected' : '') + ' td-panel'" @click="colSelected = index">
                <div class="d-flex outerDiv">
                  <div class="d-flex innerDiv td-panel" v-for="key in cols[index].rows" :key="key" :style="'background-image: url(\''+ bg_img + '\')'"> 
                        <b-form-input
                          :id = "'vdiv-' + index"
                          type="number"
                          size = "sm"
                          v-model.number = division.rows[key-1].height
                          class="text-center"
                          :name="'hdiv['+index+']'"
                        />
                  </div>
                </div>
              </td>
              <td class="tapeta" v-if="!register && tapeta[3]"></td>              
            </tr>
            <tr v-if="tapeta[1]">
              <td class="tapeta" :colspan="tapetaCol + vdivisions.length - 1"></td>

            </tr>               
          </table>  
      </b-row>
      <div v-for="division,index in vdivisions" :key="index" class="divisions">
        <b-row class="mx-1 mb-3" v-if="index == colSelected" >
          <b-col cols="4" class="mt-1">
            Divisiones Panel {{index + 1}} :
          </b-col>
          <b-col cols="8">
            <input type="number" class="form-control" 
             v-model.number="cols[index].rows" 
            @input='rerow(index)'>
          </b-col>
        </b-row>
      </div>
        <b-button type="button" variant="success" block @click="next"> Continuar </b-button>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  BCard,
  BCol,
  BRow,
  BFormInput,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BFormInput,
  },
  data() {
    return {
      colSelected : 0,
      cols : [],
      vdivisions : [],
      table_y : 400,
      register_y : 0,
      table_x : 0,
      bg_img: require('@/assets/images/darrow.png')
    };
  },
  created () {
    this.vdivisions = this.divisions
    for (let i = 0; i < this.vdivisions.length; i++) {
      this.cols.push({
        width : (this.vdivisions[i].width / this.total_width) * 100,
        rows : 1
      })
    }
  },
  mounted () {
    let clientWidth = document.getElementById('esquema').clientWidth;
    clientWidth = clientWidth > 600 ? 600 : clientWidth;
    clientWidth = this.tapeta[2] ? (clientWidth -25) : clientWidth;
    clientWidth = this.tapeta[3] ? (clientWidth -25) : clientWidth;
    this.table_x = clientWidth;  
    this.register_y = (clientWidth * this.register) / this.total_width;
    this.table_y = (clientWidth * this.total_height) / this.total_width;
  },  
  methods : {
    rerow(index){
      let y_unit = (Math.floor(((this.total_height / this.cols[index].rows) + Number.EPSILON) * 100) /100);
      this.vdivisions[index].rows = []
      for (let i = 0; i < this.cols[index].rows; i++) {
        this.vdivisions[index].rows.push({height : y_unit})
      }
    },
    next() {
      for (let i = 0; i < this.vdivisions.length; i++) {
        let y_height = 0;
        for (let j = 0; j < this.vdivisions[i].rows.length; j++) {
          if (this.vdivisions[i].rows[j].height == null || this.vdivisions[i].rows[j].height < 1) {
            this.$bvToast.toast('Las medidas de un panel no pueden ser 0',
            {variant: 'danger', title : 'Error' });
            return 0;
          }
          y_height += this.vdivisions[i].rows[j].height; 
        }
        if(y_height != this.total_height){
          this.$bvToast.toast('Las medidas introducidas en el panel' + (i+1) +' ( ' + y_height + ' ) no coinciden con la altura del marco.',
          {variant: 'danger', title : 'Error' });
          return 0;
        }
      }
      this.$store.commit('ventanas/setDivisions', this.vdivisions)
      this.$router.push('/dessign');
    }
  },
    computed: {
    ...mapState({
      register : state => state.ventanas.complements.registro_medida,
      divisions : state => state.ventanas.divisions,
      tapeta : state => state.ventanas.complements.tapeta_values,      
      total_width : state => state.ventanas.dimensions.width,
      total_height : state => state.ventanas.dimensions.height,
    }),
    tapetaRow : function () {
      let rowspan = 1;
      if(this.tapeta[0]){
        rowspan++;
      }
      if (this.tapeta[1]) {
        rowspan++;
      }
      return rowspan;
    },
    tapetaCol: function () {
      let colspan = 1;
      if(this.tapeta[2]){
        colspan++;
      }
      if (this.tapeta[3]) {
        colspan++;
      }
      return colspan;
    }
  }
};
</script>

<style>
.selected {
  background-color: lightcoral;
}
/* table, td  {
   border: 1px solid black !important; 
} */
td {
  padding: 0 !important;
}
.innerDiv{
  height: 100%;
  align-items : center;
  border : 1px solid black;
  padding : 5px ;
  background-color : rgba(255,255,255,.2 ) !important
}
.outerDiv {
  height : 100% ;
  flex-direction :column;
  justify-content: space-evenly
}
.td-panel{
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.divisions {
  max-width: 600px;
  margin: auto;
}
</style>